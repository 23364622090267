<template>
  <modal :show.sync="Modal"
         class="modal-black" centered :appendToBody="false"
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <h3 class="card-title">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>

    <template>

      <ValidationObserver v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(guardar)">

            <span class="text-muted">No. de serie: {{datos.linkID}}</span>
            <ValidationProvider name="Etiqueta"
                                rules="required"
                                v-slot="{ passed, failed, errors }">
              <MazInput v-model="datos.etiqueta"
                        placeholder="Etiqueta"
                        :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                        required :loading="loading || cargando" class="maz-mb-3"
                        clearable>
                <template v-slot:icon-left>
                  <i class="fas fa-tag"></i>
                </template>
              </MazInput>
            </ValidationProvider>




            <MazInput v-model="datos.descripcion"
                      placeholder="Comentarios" :loading="loading || cargando"
                      :autocomplete="autocompleteComentario" :disabled="loading || cargando"
                      left-icon-name="comment" class="maz-mb-3"
                      textarea />
            <UbicacionesSelect placeholder="Sucursal / Ubicación" :loading="cargando"
                               v-model="datos.ubicacionID" class="maz-mb-3" />
            <div class="row ">
              <div class="col-md-6 pb-3">

                <DarAccesoSelect placeholder="Dar Acceso" :loading="cargando"
                                 v-model="datos.darAcceso" />
              </div>
              <div class="col-md-6 pb-3">
                <TransformarAccesoSelect placeholder="Transformar Acceso" :loading="cargando"
                                         v-model="datos.transformarAcceso" />
              </div>
            </div>
            <hr class="my-md-4" />
            <base-button v-if="!cargando" native-type="submit" slot="footer" type="primary" block>
              Guardar
            </base-button>

            <div class="text-muted text-center">

              <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
                {{ Erroneo }}
              </p>
              <div v-if="cargando" class="text-center mt-7">
                <b-spinner type="grow" label="cargando..."></b-spinner>
                Cargando...
              </div>
              <router-link :to="{}" class="d-block mt-2" @click.native="Cerrar()">
                <i class="fas fa-chevron-left"> </i> Cancelar
              </router-link>

            </div>
</form>
      </ValidationObserver>
    </template>

  </modal>
</template>

<script>
  import Servicio from '@/api-services/dispositivos.service';
  import UbicacionesSelect from '@/Vistas/Ubicaciones/Select.vue';
  import DarAccesoSelect from '@/Vistas/Dispositivos/DarAccesoSelect.vue';
  import TransformarAccesoSelect from '@/Vistas/Dispositivos/TransformarAccesoSelect.vue';
  export default {
    name: 'Dispositivos-Edicion',
    components: {
      UbicacionesSelect, DarAccesoSelect,
      TransformarAccesoSelect
    },
    props: {
      value: Object,
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Dispositivos' },
      Titulo: { type: String, default: 'Edición' },
      Ayuda: {
        type: String, default: 'Son los relojes checadores, controladores de acceso, kioscos, y más.' },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          etiqueta: '',
          comentarios: '',

        },

        Modal: false,
        cargando: false,
        cargadoID: '',

        Erroneo:''
      }
    },
    computed: {
      autocompleteLinkID() {
        return `${this.$options.name}-LinkID`;
      },
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },
      Config() {
        {
          return {
            labelKey: this.campoEtiqueta,
            searchKey: this.campoEtiqueta,
            valueKey: this.campoValor
          };
        }
      },
    },

    watch: {
      value(nuevoValor) {
        this.datos = Object.assign({}, nuevoValor);
        console.debug(this.datos);
      },
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
          
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() { this.Modal = this.Mostrar; this.datos = Object.assign({}, this.value);
      this.CargarDatos();
    },
    created() {
    },
    methods: {
      CargarDatos() {
        if (this.cargadoID != this.datos.id) {
          this.cargadoID = "";
          Servicio.get(this, this.datos.id, (resonse) => {
            this.cargadoID = this.datos.id;
            this.datos = resonse.data;
          }, () => {
            this.Modal = false;
          });

        }
      },
      cargaVacio() {
        this.datos = Object.assign({}, {
          etiqueta: '',
          comentarios: '',
          ubicacionID: '',
        });
      },
      guardar() {
        Servicio.put(this, this.datos.id, this.datos, (response) => {
          this.$emit('guardado', response.data);
         
          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="pt-2 pt-md-8">

      <div class="row">
        <div class="col-lg-7 col-md-9 d-inline-flex ">
          <i class="fas fa-fingerprint fa-3x mr-2"></i>
          <p>{{Ayuda}}</p>

        </div>
        <div class="col-lg-5 col-md-3 ">
          <b-row align-h="end" class="m-2">
            <base-button icon
                         round :loading="cargando" @click="Actualiza">
              <i class="fas fa-sync"></i>
            </base-button>

            <base-button icon @click="MostrarFiltrar=true"
                         round :loading="cargando || error.length>0">
              <i class="fas fa-filter"></i>
            </base-button>


            <base-button icon type="primary" @click="MostrarNuevo=true"
                         round>
              <i class="fas fa-plus"></i>

            </base-button>

          </b-row>
        </div>
      </div>



    </div>

    <div class=" mt-2">


      <base-alert v-if="error" type="danger">
        <strong>Error:</strong> {{error}}
      </base-alert>
      <section v-else>
        <div v-if="cargando" class="text-center mt-7">
          <b-spinner type="grow" label="cargando..."></b-spinner>
          Cargando...
        </div>
        <div v-else class="card-columns-listado ">

          <!--
            Probe lo siguente y no funciono
            <router-link :to="{ path: '/personal', params: { id: registro.id }}">
          -->


          <div v-for="registro in info.listado" v-bind:key="registro.id" class="card  shadow mb-3 " :style="`border-left: 10px solid ${registro.Desconectado?'var(--danger)':'var(--success)'};`" >


            <div class="card-header border-0 p-1  ">
              <router-link :to="{}" class="pull-right p-2" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fa fa-times"></i></router-link>
              <router-link :to="{  } " @click.native="Editar(registro)">
                <!--<img :src="registro | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">-->
                <b-iconstack class="float-left p-2 mr-4 ml-2" scale="2">
                  <b-icon stacked icon="cloud-fill" variant="info" scale="0.75"></b-icon>
                  <b-icon v-if="registro.Desconectado==null" stacked icon="question" variant="danger"></b-icon>
                  <b-icon v-if="registro.Desconectado" stacked icon="slash-circle" variant="danger"></b-icon>
                </b-iconstack>
                <div class="">
                  <h4 class="mb-0 pb-0 text-break ">{{registro.etiqueta}}</h4>
                  <h5 class="mb-0 pb-0 text-muted">{{registro.linkID}} {{registro.descripcion}}</h5>
                </div>
              </router-link>


            </div>

          </div>


        </div>

      </section>
    </div>

    <EdicionModal v-if="MostrarEdicion" :Mostrar.sync="MostrarEdicion" :Ayuda="Ayuda" v-model="Seleccionado"
                  v-on:guardado="Actualiza"></EdicionModal>

    <FiltrarModal v-if="MostrarFiltrar" :Mostrar.sync="MostrarFiltrar" Categoria="Grupos" v-model="BaseGetsListado.filtro"
                  v-on:filtrar="OnFiltrar"></FiltrarModal>

    <NuevoModal v-if="MostrarNuevo" :Mostrar.sync="MostrarNuevo" :Ayuda="Ayuda" key="NuevoModal"
                v-on:guardado="Actualiza"></NuevoModal>

  </div>
</template>
<script>
  import Servicio from '@/api-services/dispositivos.service';
  import NuevoModal from './Nuevo.modal.vue';
  import EdicionModal from './Edicion.modal.vue';
  import FiltrarModal from '@/Vistas-base/Main/Filtrar.modal.vue';
  import swal from 'sweetalert2';

  export default {
    name: 'Dispositivos-listado',
    data() {
      return {
        MostrarFiltrar: false,
        MostrarNuevo: false,
        MostrarEdicion: false,

        BaseGetsListado: {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 50,
          filtro: '',
        },

        Seleccionado: null,

        Ayuda: 'Son los relojes checadores, controladores de acceso, kioscos, y más.',
        info: null,
        cargando: true,
        error: ''
      }
    },
    components: {
      FiltrarModal,
      EdicionModal,
      NuevoModal,
    },
    filters: {
      Foto(Registro) {
        return Servicio.FotoURL(Registro);
      },

    },
    destroyed() {
      console.log("Componente eliminado");
      this.$socket.off('OnConexion', this.OnConexion);
      this.$socket.off('OnAcceso', this.OnAcceso);
    },
    mounted() {
      this.Actualiza();
      this.$socket.on('OnConexion', this.OnConexion);
      this.$socket.on('OnAcceso', this.OnAcceso);
    },

    methods: {
      OnAcceso(resultado) {
        console.debug(resultado);
      },
      OnConexion(resultado) {

        console.debug(resultado);
        var E = this.info.listado.filter(item => item.linkID == resultado.noSerie);
        if (E.length > 0) {
          E[0].Desconectado = !resultado.conectado;
        }

      },
      Borrar(seleccionado) {
        console.debug(seleccionado.id);
        swal.fire({
          title: '¿Estas seguro?',
          text: `Estas por borrar un dispositivo, se borrará la información dentro de el`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn  btn-danger ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si, Borrarlo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then((resultado) => {
          console.debug(resultado);
          if (resultado.isConfirmed)

            Servicio.delete(this, seleccionado.id, (response) => {
              //console.debug(response.data);
              swal.fire({
                title: 'Borrado',
                text: 'El registro se ha borrado satisfactoriamente.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-fill'
                },
                buttonsStyling: false
              });
              this.Actualiza();
            });

        });
      },
      Editar(seleccionado) {
        this.MostrarEdicion = false;
        this.Seleccionado = seleccionado;

        this.MostrarEdicion = true;
      },
      OnFiltrar(filtro) {
        //     this.BaseGetsListado.Filtro = filtro;
        this.Actualiza();
      },
      Actualiza() {
        //     console.debug(this.$appInsights);
        // this.$appInsights.loadAppInsights();
        /*   this.$appInsights.context.user.authenticatedId = `My authenticated Id`;
           this.$appInsights.context.user.accountId = `My Account Id`;
           this.$appInsights.context.user.localId = `My Local Id`;*/

        //   console.debug(this.$appInsights);
        this.error = '';
        // this.cargando = true;
        Servicio.gets(this, this.BaseGetsListado, (response) => {

          //console.debug(this.info);
          response.data.listado.forEach((element) => {
            if (element.linkID != null && element.linkID != '') {
              element.Desconectado = true;
              this.$socket.invoke('OnConecta', element.linkID);
            }
          });
          //Si no se cambia primero no se verifican los cambios
          this.info = response.data;

          //          this.$notify({ type: 'info', title: "Listado obtenido" });
        }, (MsgError, error) => {
          // console.debug(MsgError);
          this.error = MsgError;
          //this.$notify({ type: 'danger', title: MsgError });
        }, () => {
          this.cargando = false;

        });

      },

    }
  }</script>
<style lang="scss" scoped>



  @media (min-width: 35em) {
    .card-columns-listado {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 55em) {
    .card-columns-listado {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 80em) {
    .card-columns-listado {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 97em) {
    .card-columns-listado {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      orphans: 1;
      widows: 1;
    }
  }

  .card {
    display: inline-block; /*    // Don't let them vertically span multiple columns */
    width: 100%; /*    // Don't let their width change*/
  }
</style>

<template>
  <modal :show.sync="Modal"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
    <card type="secondary"
          class="border-0 mb-0">
      <template slot="header">
        <!-- <i class="fas fa-users fa-3x"></i>-->
        <h5 class="card-category">{{Categoria}}</h5>
        <h3 class="card-title">{{Titulo}}</h3>
        <span class="text-muted">{{Ayuda}}</span>
      </template>


      <template>

        <ValidationObserver v-slot="{ handleSubmit}">
          <form @submit.prevent="handleSubmit(filtrar)">

            <MazInput v-model="Query"
                      placeholder="Filtro"
                      :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                      class="maz-mb-3" :loading="loading || cargando"
                      left-icon-name="search"
                      clearable />
            <base-checkbox class="px-2" v-model="Borrados" >
              Mostrar solo borrados
            </base-checkbox>
            <hr class="my-4" />
            <base-button v-if="!cargando" native-type="submit" slot="footer" type="primary" round block>
              Filtrar
            </base-button>

            <div class="text-muted text-center">

              <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
                {{ Erroneo }}
              </p>
              <div v-if="cargando" class="text-center mt-7">
                <b-spinner type="grow" label="cargando..."></b-spinner>
                Cargando...
              </div>
              <a href="#" class="d-block mt-2" @click="Cerrar()">
                <i class="fas fa-chevron-left"> </i> Cancelar
              </a>

            </div>
          </form>
        </ValidationObserver>
      </template>

    </card>
  </modal>
</template>

<script>


  export default {
    name: 'Main-FiltrarModal',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      SoloBorrados: { type: Boolean, default: false },
      value: { type: String, default: 'La ' },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Categoria: { type: String, default: 'La Categoria' },
      Titulo: { type: String, default: 'Filtrar Datos' },
      Ayuda: { type: String, default: 'Introduce el texto por el cual deseas filtrar los datos.' },
    },
    data() {
      return {
        Query:'',

        Modal: false,
        cargando: false,
        Borrados: false,
        Erroneo:''
      }
    },
    computed: {
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {
      Borrados(Borrados) {
        //if (!Borrados)
        console.debug(Borrados);
        this.$emit('update:SoloBorrados', Borrados);
          //this.$nextTick(() => { this.$emit('update:soloborrados', Borrados); });
      },
      SoloBorrados(SoloBorrados) {
        console.debug(SoloBorrados);
        this.Borrados = SoloBorrados;
      },
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Query = this.value;
          this.Modal = true;
        }
        else
          this.Modal = false;
        
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;
      this.Borrados = this.SoloBorrados;
      this.Query =   this.value;
    },
    created() {
    },
    methods: {
      filtrar() {
        

        this.$emit('input', this.Query);
          this.Cerrar();

        this.$emit('filtrar', this.Query,this.Borrados);
        
      },
      Cerrar() {
        this.Query = '';
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>

<template>
  <div class="">
    <MazSelect v-model="selectedItem"
               :placeholder="placeholder"
               :loading="loading || cargando"
               :options="valores"
               clearable 
               :disabled="disabled" :required="required"
               :position="position"
               :no-data="!valores && !valores.length"
               :config="Config"
               listWidth="100%"
               :inputValue="inputValue"

               :size="size"
               >


    </MazSelect>


  </div>
</template>

<script>
  import { MazSelect } from 'maz-ui'


  export default {
    name: 'DarAcceso-Select',
    components: {
      MazSelect,
    },
    props: {
      loading: { type: Boolean, default: false },

      value: { type: String, default: null },
      id: { type: String, default: null },
      disabled: { type: Boolean, default: false }, required: { type: Boolean, default: false },

      // Same as MazInput (options: `sm|md|lg`)
      size: { type: String, default: null },


      // Etiqueta a mostrar
      campo: { type: String, default: 'Transformar checada' },

      // Etiqueta a mostrar
      placeholder: { type: String, default: 'Transformar checada' },
      // Enable the dark mode
      dark: { type: Boolean, default: false },
      // Use color
      color: { type: String, default: 'primary' },



      // set the position of countries list (ex: `top`, `top right`, `bottom right`)
      position: { type: String, default: 'left bottom' },

      //valores: { type: Array, default: [] },


    },
    data() {
      return {
        MostrarNuevo: false,
        selectedItem: null,
        cargando: false,

        inputValue: '',
        Erroneo: '',
        valores:
          [
            { label: 'Dejar como diga el dispositivo', value: 'No' },
            { label: 'Las checadas a Entrada', value: 'Entrada' },
            { label: 'Las checadas a Salida', value: 'Salida' },
            { label: 'Las checadas a Correcto', value: 'Correcto' },
          ],
      }
    },
    computed: {

      Config() {
        {
          return {
            labelKey: "label",
            searchKey: "label",
            valueKey: "value"
          };
        }
      },
    },

    watch: {
      value(valor) {

        this.selectedItem = valor;
        console.debug(valor);
      },
      selectedItem(valor) {
        
        //this.$emit('update:value', valor);
        this.$emit('input', valor);
        //  this.$set(this, 'value', valor);
        // this.value = valor;
      }
    },
    methods: {
      OnGuardado(Datos) {
        
        this.valores.push(Datos);
        this.selectedItem = Datos.id;
        /*
          //Actualiza todo el listado
        this.Actualiza(() => {
          this.selectedItem = Datos.id;
        });
        */
      },

      Actualiza(OnFinalizado) {

      }
    },

    mounted() {
      if (this.value != null)
        this.selectedItem = this.value;
      this.Actualiza();
    },
  }

</script>
<style src="maz-ui/lib/css/index.css"></style>
<style lang="scss" scoped>
  .option {
    margin-top: auto;
    margin-bottom: auto;
  }
</style>


import Base from '@/api-base/base'
const BaseURL = `Usuarias`;

export default {
  GetsResponse: null,
  Listado: [],
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSelect(Vista,  OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(Vista, BaseURL, { PaginaLen:1000}, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  patch(Vista, ID,  OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(Vista, URL, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, BaseURL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getBienvenida(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}/Bienvenida`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(Vista, `${BaseURL}/${id}`, OnCorrecto, OnErroneo, OnFinalizado);
  },
  sTipoUsuaria2Etiqueta(TipoUsuaria) {
    switch (TipoUsuaria) {
      case "UsuarioAvanzado":
        return "Usuario Avanzado";
      case "UsuarioRegular":
        return "Usuario Regular";
    }
    return TipoUsuaria;
  },
  FotoURL(Usuaria) {
    var R = '/img/default-avatar.png';
    // console.debug(Colaborador);
    if (Usuaria != undefined && Usuaria != null && Usuaria.urlMiniatura != undefined && Usuaria.urlMiniatura != null && Usuaria.urlMiniatura != "") {
      R = Usuaria.urlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }

    //console.debug(R);
    return R;
  },
  //Agrega o actualiza la foto de un colaborador
  FotoPatch(Vista, ID, Orden, Archivo, OnUploadProgress, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/Foto?Orden=${Orden}`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: OnUploadProgress
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
}
